import { useCallback } from 'react';
import Button from './Button';

const CsvButton = props => {
  const { data, headers, fileName, children, onClick, ...opts } = props;

  const downloadCSV = useCallback(() => {
    // Convert the data array into a CSV string
    const replacer = (_key, value) => (value === null ? '' : value);
    const csvString = [
      headers.map(h => h.label), // Specify your headers
      ...data.map(item =>
        headers.map(h => JSON.stringify(item[h.key], replacer)),
      ), // Map your data fields accordingly
    ]
      .map(row => row.join(','))
      .join('\r\n');

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'download.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [headers, data]);

  const handleClick = e => {
    downloadCSV();

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button {...opts} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default CsvButton;
