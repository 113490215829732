import { gql } from '@apollo/client';

export default gql`
  query getChecksPrint($projectId: String) {
    checksPrint(projectId: $projectId) {
      clientNumber
      clientName
      producerNumber
      producerName
      productionComp
      prodcoName
      projectNumber
      projectName
      invoiceApproved
      checkStatus
      totalChecks
      checksPrinted
      checksNotPrinted
      invoiceReleased
      totalInvoice
      checksApproved
      checksNotApproved
      dbCode
    }
  }
`;
