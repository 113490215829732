import { useState } from 'react';
import qs from 'qs';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import T from 'components/CncUI/Typography';
import Button from 'components/CncUI/Button';
import Dropdown from 'components/CncUI/Dropdown';

import Grid from '@mui/material/Grid';
import Buttons from './Buttons';
import CsvButtons from './CsvButtons';
import Modals from './Modals';
import SideSheets from './SideSheet';
import Containers from './Containers';
import Cards from './Cards';
import RadioCards from './RadioCards';
import TextFields from './TextFields';
import Dropdowns from './Dropdowns';
import Tooltips from './Tooltips';
import Steppers from './Steppers';
import Alerts from './Alerts';
import Snackbars from './Snackbars';
import Tables from './Tables';
import DataTables from './DataTables';
import Typography from './Typography';
import Checkboxes from './Checkboxes';
import RadioButtons from './RadioButtons';
import Accordions from './Accordions';
import DatePickers from './DatePickers';
import DateTimePickers from './DateTimePickers';
import Switches from './Switches';
import Progress from './Progress';
import Tabs from './Tabs';
import Breakpoints from './Breakpoints';
import AddressCapture from './AddressCapture';
import AppBar from './AppBar';
import Tags from './Tags';
import PageDots from './PageDots';
import FileUploadDownload from './FileUploadDownload';
import Chips from './Chips';
import ReAuthenticate from './ReAuthenticate';

import Loader from 'components/Loader';

const useStyles = makeStyles(({ palette }) => ({
  '@global': {
    body: {
      backgroundColor: palette.cncUI.default.background,
    },
    'h1.header': {
      color: palette.cncUI.default.primary,
      borderBottom: '4px solid',
      lineHeight: '20px',
    },
    'h2.header': {
      color: palette.cncUI.default.primary,
      borderBottom: '2px solid',
      lineHeight: '16px',
    },
    'h3.header': {
      color: palette.cncUI.default.primary,
      borderBottom: '1px solid',
      lineHeight: '12px',
    },
  },
}));

const ALL = 'ALL';

const list = [
  { name: 'Breakpoints', component: Breakpoints },
  { name: 'Typography', component: Typography },
  { name: 'Buttons', component: Buttons },
  { name: 'CsvButtons', component: CsvButtons },
  { name: 'TextFields', component: TextFields },
  { name: 'AddressCapture', component: AddressCapture },
  { name: 'Dropdowns', component: Dropdowns },
  { name: 'DatePickers', component: DatePickers },
  { name: 'DateTimePickers', component: DateTimePickers },
  { name: 'Big Files', component: FileUploadDownload },
  { name: 'Checkboxes', component: Checkboxes },
  { name: 'RadioButtons', component: RadioButtons },
  { name: 'Switches', component: Switches },
  { name: 'Tooltips', component: Tooltips },
  { name: 'Tags', component: Tags },
  { name: 'Progress', component: Progress },
  { name: 'Tabs', component: Tabs },
  { name: 'Accordions', component: Accordions },
  { name: 'Modals', component: Modals },
  { name: 'SideSheets', component: SideSheets },
  { name: 'Containers', component: Containers },
  { name: 'Cards', component: Cards },
  { name: 'RadioCards', component: RadioCards },
  { name: 'PageDots', component: PageDots },
  { name: 'Steppers', component: Steppers },
  { name: 'Alerts', component: Alerts },
  { name: 'Snackbars', component: Snackbars },
  { name: 'Tables', component: Tables },
  { name: 'DataTables', component: DataTables },
  { name: 'Chips', component: Chips },
  { name: 'ReAuthenticate', component: ReAuthenticate },
];

const components = list.map(item => item.component);
const names = list.map(item => item.name);
const findComponent = name => list.find(item => item.name === name)?.component;

const Showcase = ({ location }) => {
  useStyles();
  const [component, setComponent] = useState(
    qs.parse((location.search || '?').slice(1))?.component || ALL,
  );

  if (!['local', 'qa'].includes(process.env.REACT_APP_ENVIRONMENT)) {
    window.location.replace('/');
    return <Loader />;
  }

  const Component = findComponent(component);

  const copy = () => {
    const url = `${window.location.origin}/Showcase?component=${component}`;
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <AppBar />
      <Container style={{ paddingBottom: 40 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ marginTop: 24 }}>
              <Grid item>
                <T variant="body2" size="long" medium>
                  Display Components:
                </T>
              </Grid>
              <Grid item>
                <Dropdown
                  light
                  value={component}
                  onChange={e => setComponent(e.target.value)}
                >
                  <Dropdown.Item value={ALL}>All</Dropdown.Item>
                  {names.map(name => (
                    <Dropdown.Item key={name} value={name}>
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </Grid>
              <Grid item>
                <Button tertiary onClick={copy}>
                  Copy Link
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {component === ALL &&
                components.map((Component, i) => (
                  <Grid item xs={12} key={i}>
                    <Component />
                  </Grid>
                ))}
              {Component && <Component />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Showcase;
