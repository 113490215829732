import { gql } from '@apollo/client';

export default gql`
  query PendingApprovals($pid: String) {
    pendingApprovals(pid: $pid) {
      timecards {
        count
        url
      }
      invoices {
        count
        url
      }
    }
  }
`;
