import { gql } from '@apollo/client';

export default gql`
  query Invoices($pid: String) {
    invoices(pid: $pid) {
      invoiceName
      invoiceNumber
      departments
      status
      totalTimecards
      viewInvoiceUrl
    }
  }
`;
