import { gql } from '@apollo/client';

export default gql`
  query CrewDetails($pid: String) {
    crewDetails(pid: $pid) {
      employeeName
      maskedSsn
      employeeEmailAddress
      jobDescription
      department
      account
      employeeAddress
      guaranteePay
      guaranteeHr
      guaranteeUnit
      wkschdDescNote
      phoneNumber
      startDate
      endDate
      unionId
      kitRental
      kitAcct
      carAllow
      carAcct
      cellAllow
      cellAcct
      perDiemMealAllow
      perDiemMealAcct
    }
  }
`;
