import { gql } from '@apollo/client';

export default gql`
  query UserDashboardSettings {
    user {
      settings {
        initialSetupAt
        dashboardView
        primaryRole
        selectedProject
      }
      projects {
        pid
        name
        apps
        productionCompanyId
        productionCompany
        producerId
        producer
        clientId
        client
        createdAt
      }
    }
  }
`;
