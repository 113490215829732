import { gql } from '@apollo/client';

export default gql`
  query UploadedTimecards($projectId: String) {
    uploadedTimecards(projectId: $projectId) {
      filePath
      fileName
      locationName
      locationPath
      count
      createdAt
      status
    }
  }
`;
