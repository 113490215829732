export default {
  // GENERIC BUTTONS & ALERTS
  BUTTON_CANCEL: 'Cancel',
  BUTTON_CLOSE: 'Close',
  BUTTON_CONTINUE: 'Continue',
  BUTTON_DONE: 'Done',
  BUTTON_SEND_REMINDER: 'Send Reminder',
  BUTTON_SELECT_ALL: 'Select All',
  BUTTON_DESELECT_ALL: 'Deselect All',
  ALERT_ERROR_LABEL: 'An error has occurred.',
  ALERT_ERROR_CONTENT: 'Please try again or check back shortly.',
  SNACKBAR_SUCCESS_REMINDER: 'Reminder sent',
  //SETUP
  HI_RIGHT_DASHBOARD: 'Hi <<var1>>, let’s set up your dashboard.',
  WE_ASK_QUESTIONS:
    'We’ll gather a few details about your role and workflow to create the best experience for you.',
  PRIMARY_ROLE: 'Primary role',
  SELECT_BEST_TITLE: 'Select the title that best describes your primary role',
  SELECT_ROLE: 'Select a role',
  DASHBOARD_VIEW: 'Dashboard view',
  SELECT_PREFERRED_DEFAULT: 'Select your preferred default dashboard',
  SELECT_VIEW: 'Select a view',
  PAYROLL_DASHBOARD: 'Payroll Dashboard',
  VIEW_PAYROLL_WORKFLOW:
    'Easily process payroll, track invoices, and resolve discrepancies--all in one place.',
  ACCOUNTING_DASHBOARD: 'Accounting Dashboard- ',
  COMING_SOON: 'Coming soon!',
  VIEW_ACCOUNTING_WORKFLOW:
    'Keep track of budgets, expenses, and financial reports. While this dashboard isn’t available yet, you can select this option to add it to your view when it’s ready.',
  PERSONAL_DASHBOARD: 'Personal Dashboard',
  VIEW_PERSONAL_DATA:
    'This view shows your personal employee pay and offer data',
  SELECT_PROJECT: 'Search for a project',
  SELECT_PROJECT_ACCESS: 'Select the project you would like to access first',
  TAKE_TO_DASHBOARD: 'Go to my dashboard',
  //HERO
  HI: 'Hi ',
  HI_WELCOME: 'Hi <<var1>>, welcome',
  YOUR_DASHBOARD: 'to your dashboard.',
  TODAY_IS: 'Today is <<var1>>,',
  TODAY_IS_2: 'Today is <<var1>>. Here’s what you’ve got going on.',
  LETS_EXPLORE: "Let's explore what's available for you today.",
  LAST_UPDATED: 'Last updated <<var1>>',
  THIS_IS: 'this is what you have going on.',
  HERE_ARE_SOME: 'Here are some resources you might find useful.',
  HERE_CAN_FIND:
    'Here you can find useful resources to help you manage your tasks.',
  NOTHING: 'Nothing',
  GOT_NOTHING: 'You got nothing going on today.',
  LOAN_OUT_RENEWAL: 'Loan-out Renewal',
  LOAN_OUT_EXPIRED: 'Your loan-out is expired, ...',
  ADDRESS_CONFIRMATION: 'Address confirmation',
  INFORMATION_CORRECT: 'Is your information still correct',
  TD1_RENEWAL: 'TD-1 Renewal',
  TD1_EXPIRED: "Your TD-1 is expired, let's renew",
  PAY_AMOUNTS_TOGGLE: 'Pay Amounts Toggle Switch',
  FOR_YOUR_PRIVACY:
    'For your privacy, pay amounts are initially hidden. You can reveal them anytime using the toggle switch.',
  CONTINUE: 'Continue',
  DONT_SHOW_AGAIN: "Don't show again",
  //OFFER
  OFFERS: 'Offers',
  RECENT_OFFERS: 'Recent Offers',
  SHOWING: 'Showing',
  HIDING: 'Hiding',
  AMOUNTS: 'amounts',
  SHOW_AMOUNTS: 'Show amounts',
  START_ON: 'start on or about',
  HOURLY: 'hourly',
  CURRENCY: 'USD',
  VIEW_OFFERS: 'View all offers',
  RATE: 'Rate',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  NO_OFFERS: 'No digital offers yet',
  DIGITAL_OFFERS: 'Start+ offers',
  NO_DIGITAL_OFFERS:
    "All your Start+ offers will appear here once they're available",
  ONLY_DIGITAL_OFFERS: 'Only Start+ offers will appear here.',
  START_DATE: 'Start Date',
  //PAYSTUB
  RECENT_PAYSTUBS: 'Recent Paystubs',
  PAYSTUBS: 'Paystubs',
  GROSS_PAY: 'Gross Pay',
  TAXES: 'Taxes',
  HOURS: 'Hours',
  CHECK_NO: 'Check No.',
  VIEW_ALL_PAYSTUBS: 'View all paystubs',
  PAY_PERIOD: 'Pay Period',
  WORK_PERIOD: 'Work Period',
  OVERRIDE_PERIOD: 'Override Period',
  PAID: 'Paid',
  NET_PAY: 'Net Pay',
  NO_RECENT_PAYSTUBS: 'No recent paystubs yet',
  NO_PAYSTUBS: 'No pay stubs to show',
  ONCE_RECEIVE_PAYSTUB:
    'Paystubs from Cast & Crew will appear here once they’re available.',
  ONCE_AVAILABLE_CAPS:
    'Paystubs from Cast & Crew and CAPS will appear here once they’re available.',
  PAYSTUBS_SHOWN_HERE:
    "Paystubs shown here reflect only the previous day's activity and will only include paystubs from Cast & Crew and CAPS. To see all your Cast & Crew, CAPS, and Media Services paystubs as of today, check the Pay Stubs page.",
  PAYSTUB_PREVIEW: 'Pay stub preview',
  PAYROLL_SERVICE: 'Payroll Service',
  EFFECTIVE_DATE: 'Effective Date',
  NO_GARNISHMENTS: 'No garnishments to show',
  DOWNLOAD_PDF: 'Download PDF',
  ZOOM_IN: 'Zoom in',
  ZOOM_OUT: 'Zoom out',
  //TIMECARDS
  RECENT_TIMECARDS: 'Recent Timecards',
  CREATE: 'Create',
  CREATE_NEW: 'Create New',
  CREATE_TIMECARD: 'Create timecard',
  SELECT_TIMECARD_CREATE: 'Select a project to create a timecard.',
  SELECT_TIMECARD_VIEW: 'Select a project to see all timecards.',
  VIEW_TIMECARDS: 'View all timecards',
  WORK_WEEK: 'Work Week',
  HOURS_WORKED: 'hours worked',
  REVISE: 'Revise',
  SUBMIT: 'Submit',
  REVIEW: 'Review',
  VIEW: 'View',
  DIGITAL_TIMECARDS: 'Hours+ timecards',
  NO_DIGITAL_TIMECARDS: 'No digital timecards yet',
  ONLY_DIGITAL_TIMECARDS:
    "Only Hours+ timecards you've created or that have been created for you will appear here",
  ALL_TIMECARDS_CREATED:
    'All Hours+ timecards, whether created by you or prepared for you, will appear here.',
  SEE_MORE_PROJECTS: 'See more projects',
  SIGN: 'Sign',
  NO_TIMECARDS:
    'All pending Hours+ timecards available to you will appear here. If you have access, visit Hours+ for more information.',
  NO_YET_TIMECARDS: 'No timecards yet',
  OOPS_TIMECARDS:
    'Our data elves took a coffee break. Try refreshing the page or check back soon.',
  NO_YET_EDITINVOICE: 'No edits / invoices yet',
  NO_EDITINVOICE_DESC:
    'All pending edits and invoices available to you will appear here. Navigate to the Digital Edits page for more information',
  NO_YET_UPLOADTIMECARDS: 'No timecards available yet',
  NO_UPLOADTIMECARDS_DESC:
    'All manual timecards uploaded to Studio+ will appear here. Go to Studio+ for more information',
  //YTD
  YTD_PAY: 'YTD Pay',
  YEAR_TD_PAY: 'Year-to-Date Pay',
  GROSS_EARNINGS: 'gross earnings',
  TOTAL_LAST_YEAR: 'total last year',
  SO_FAR_THIS_YEAR: 'so far this year',
  YOUR_YTD_DETAILS: 'Your year-to-date pay details',
  YOUR_YTD_SELECTED:
    'Your YTD pay details for the selected year will be displayed here if available.',
  YOUR_YTD_FIRST:
    'Your YTD pay will be displayed here as soon as you receive your first paycheck from Cast & Crew.',
  YOUR_YTD_CAPS:
    'Your YTD pay will be displayed here as soon as you receive your first paycheck from Cast & Crew or CAPS.',
  YTD_PAY_SHOWN:
    'The Year-to-Date (YTD) pay shown here reflects the previous day’s data. Updates from today will appear tomorrow.',
  TOTAL_HOURS: 'Total Hours',
  //My Tasks
  MY_TASKS: 'My Tasks',
  LOAN_OUT_CHECK_IN: 'Loan-out Check-in',
  IS_YOUR_LOANOUT: 'Is your loan-out information still correct?',
  ADD_LOANOUT: 'Add Loan-out',
  OFFER_REQUIRE_SETUP: 'An offer may require this setup',
  ADD_W4: 'Add W-4',
  OFFER_REQUIRE_DOCUMENT: 'An offer may require this document',
  INFORMATION_CHECK_IN: 'Information Check-in',
  BEEN_AWHILE_CORRECT: "It's been a while, is your information correct?",
  DIRECT_DEPOSIT: 'Direct Deposit',
  ELIGIBLE_DIRECT_DEPOSIT: "You're eligible for direct deposit.",
  SETUP: 'Set Up',
  CONFIRM: 'Confirm',
  ADD_FORM: 'Add Form',
  RENEW: 'Renew',
  LOAN_OUT: 'Loan-out',
  W4: 'W-4',
  INFORMATION: 'Information',
  NO_TASKS: 'No tasks to do',
  ALL_CAUGHT_UP: "You're all caught up",
  ANY_NEW_TASKS: 'Any new tasks will appear here for you to complete.',
  W4_RENEWAL: 'W-4 Renewal',
  W4_EXPIRED: 'Your W-4 has expired, please renew your form.',
  TD1: 'TD1',
  ADD_TD1: 'Add TD1',
  // FAQ
  FAQ_TITLE: 'Frequently Asked Questions',
  SEE_MORE: 'See more',
  SEE_LESS: 'See less',
  READ_MORE: 'Read more',
  STILL_HAVE_QUESTIONS: 'Still have questions?',
  STILL_HAVE_QUESTIONS_DESC: `Can't find the answer you're looking for? Tap
    into our self-service help center or reach out for Support.`,
  GO_SELF_SERVICE: 'Go to Self Service',
  GET_IN_TOUCH: 'Get in touch',
  // Validate Account
  VALIDATE_ACCOUNT_TITLE:
    'Need to access your paystubs and tax document forms?',
  VALIDATE_ACCOUNT_DESC: `Click the button below to validate your production
    employee account to get started.`,
  VALIDATE_ACCOUNT: 'Validate my account',
  // Learning Resources
  LR_TITLE: 'Learning Resources',
  TAKE_ME_THERE: 'Take me there',
  LR_STILL_HAVE_QUESTIONS_DESC: `Can't find the answer you're looking for?
    Tap below to reach out for Support.`,
  //Generic
  OOPS: 'Oops!',
  OUR_DATA_ELVES:
    'Our data elves took a coffee break. Try refreshing the page or check back soon.',
  SEARCH_FOR_PROJECT: 'Search for project',
  NO_PROJECTS_FOUND: 'No projects found.',
  //Payroll Dashboard
  ONBOARDING: 'Onboarding',
  TRACK_PACKETS_I9S: 'Track pending start packets and I-9s',
  NO_PENDING_HIRES: 'No offers yet',
  NO_PENDING_HIRES_DES:
    'All pending Start+ offers available to you will appear here. If you have access, visit Start+ for more information.',
  NO_VERIFY_I9: 'No I-9 verifications yet',
  NO_VERIFY_I9_DES:
    'All pending I-9 verifications will appear here. If you have access, visit Start+ for more information.',
  NO_UPLOADED_STARTS: 'No start work available yet',
  NO_UPLOADED_STARTS_DES:
    'All manual start work uploaded to Studio+ will appear here. Go to Studio+ for more information.',
  PENDING_HIRES: 'Pending hires',
  VERIFY_I9S: 'Verify I-9s',
  UPLOADED_STARTS: 'Uploaded starts',
  VIEW_IN_START: 'View in Start+',
  SEND_REMINDER: 'Send reminder',
  ADD_CREW: 'Add crew',
  VIEWING: 'Viewing',
  VIEWING_OF_ITEMS: 'Viewing <<var1>>-<<var2>> of <<var3>> items',
  ITEMS: 'items',
  CREW_MEMBER: 'Crew member',
  DEPARTMENT: 'Department',
  CURRENT_APPROVER: 'Current approver',
  ONBOARDING_STATUS: 'Onboarding status',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  SEARCH_STATUS: 'Search status, employee, start da...',
  SEARCH_BY_FOLDER: 'Search by status, file name, folder...',
  ONBOARDING_WIDGET: 'Onboarding Widget',
  CHECK_APPROVAL_STATUSES:
    'Check real-time approval and processing statuses for digital start paperwork in Start+, I-9 verification and uploaded manual start paperwork. Click a row or the kebab menu to view details or take actions.',
  EMPLOYEE_SIGNS_OFFER: 'Employee needs to sign offer',
  EMPLOYEE_SIGNS_DOC: 'Employee needs to sign new document',
  OFFER_SENT_EMPLOYEE: 'Offer will be sent to employee once reviewed',
  OFFER_PENDING_APPROVALS: 'Offer pending approvals',
  EMPLOYEE_REJECTED_OFFER: 'Employee rejected the offer back to hiring manager',
  APPROVER_REJECTED_OFFER: 'Approver rejected offer back to hiring manager',
  OFFER_NOT_SENT: 'Offer has not been sent to employee',
  DUE_DATE: 'Due date',
  DUE_TODAY: 'Due today',
  DAYS_PAST_DUE: '<<var1>> days past due',
  DUE_SPECIFIC_DATE: 'Due <<var1>>',
  PAST_DUE: 'Past due',
  UPCOMING_DUE: 'Upcoming due',
  I9_VERIFICATION_IS: 'I-9 verification is (x) many days past the start date',
  EMPLOYEE_START_TODAY: 'Employee start date is today',
  EMPLOYEE_START_FUTURE: 'Employee start date is in the future',
  FILE_NAME: 'File name',
  STUDIO_FOLDER: 'Studio+ folder',
  DATE_TIME: 'Date & time',
  TOTAL_STARTS: 'Total starts',
  STATUS: 'Status',
  UPLOAD_TO_STUDIO: 'Upload to Studio+',
  FILES_SUCCESSFULLY_UPLOADED: 'Files have successfully uploaded',
  FILES_BEEN_RECEIVED: 'Files have been received by C&C',
  RECEIVED: 'Received',
  UPLOADED: 'Uploaded',
  TIMECARDS: 'Timecards',
  TIMECARDS_WIDGET: 'Timecards Widget',
  VIEW_IN_HOURS: 'View in Hours+',
  TRACK_STATUS_TIMECARDS:
    'Track the status of digital timecards, edits, invoices, and uploaded timecards in real-time. Click a row or the 3-dot menu for more details or to take action.',
  DATA_FILTERED_PAYROLL:
    'The data is filtered by the payroll work week, with the current week-ending date displayed here.',
  GAIN_INSIGHT_TIMECARDS:
    'Gain insight into your timecard and invoice approval process',
  PAYROLL_THROUGH_WEEK: 'Payroll through week ending:',
  PENDING_TIMECARDS: 'Pending timecards',
  EDITS_INVOICES: 'Edits / Invoices',
  UPLOADED_TIMECARDS: 'Uploaded timecards',
  VIEWING_OF_GROUPS: 'Viewing <<var1>>-<<var2>> of <<var3>> groups',
  SEARCH_BY_GROUP: 'Search by status, group, approver...',
  TIMECARD_GROUPS: 'Timecard groups',
  DATE_AVAILABLE: 'Date available',
  TOTAL_TIMECARDS: 'Total timecards',
  TIMECARDS_READY_REVIEW: 'The timecards ready for you to review and approve',
  TIMECARDS_AWAITING_APPROVER:
    'The timecards are awaiting approval from an approver',
  TIMECARDS_DRAFT_INCOMPLETE:
    'The timecards are in draft, incomplete, or awaiting action from the employee or department head',
  TIMECARDS_REJECTED_AWAITING:
    'The timecards have been rejected and are awaiting re-approval',
  VIEW_ALL_INVOICES: 'View all invoices',
  INVOICE_NAME: 'Invoice name',
  INVOICE_NUMBER: 'Invoice number',
  INVOICE_STATUS: 'Invoice status',
  DEPARTMENTS_INCLUDED: 'Departments included',
  EDITS: 'Edits',
  INVOICE_REVIEW: 'The invoice is ready for you to review',
  ONE_MORE_REJECTED:
    'One or more timecards have been rejected by your coordinator and need to be reapproved',
  INVOICE_HAS_UNSUBMITTED: 'The invoice has unsubmitted revisions',
  INVOICE_APPROVED_FUTURE:
    'The invoice has been approved and will release at a future date',
  INVOICE_APPROVED_PAID: 'The invoice has been approved and paid',
  INVOICE_HOLD: 'The invoice is on hold',
  CHANGES_SENT_COORDINATOR:
    'Your requested changes have been sent back to your coordinator',
  COORDINATOR_PREPARING_INVOICE: 'Your coordinator is preparing your invoice',
  INVOICE_SUBMITTED_COORDINATOR:
    'The invoice has been submitted to your coordinator',
  INVOICE_RECEIVED_COORDINATOR:
    'The approved invoice has been received by your coordinator',
  VIEWING_OF_INVOICES: 'Viewing <<var1>>-<<var2>> of <<var3>> invoices',
  SEARCH_BY_INVOICE: 'Search by status, invoice, approve...',
  NO_RESULTS_FOUND: 'No results found',
  TIMECARDS_COMPLETED_PAPER: 'Timecards completed on paper',
  COMPLETED_ON_PAPER: 'Completed on paper',
  CREATED: 'Created: ',
  //Crew List
  YOUR_CREW: 'Your Crew',
  QUICKLY_FIND_CONTACT:
    'Quickly find contact info for your crew and download a detailed crew report including all deal points, allowances, and employee information.',
  VIEWING_OF_CREW: 'Viewing <<var1>>-<<var2>> of <<var3>> crew members',
  DETAILED_CREW_REPORT: 'Detailed crew report',
  SEARCH_BY_NAME: 'Search by name, department, role...',
  NO_CREW_ONBOARDED: 'No crew have been onboarded yet',
  ONCE_CREW_ONBOARDED:
    'Once crew are onboarded to the project, their details will appear here and in the detailed crew report.',
  REPORT_DOWNLOADED: 'Report downloaded',
  // Reminder Modals - Pending Timecards
  LAST_REMINDER_SENT: 'Last reminder sent:',
  PENDING_TIMECARD_REMINDER_TITLE_LIST: 'Send Pending Timecards Reminder',
  PENDING_TIMECARD_REMINDER_DEPTHEADS: 'All dept. heads',
  PENDING_TIMECARD_REMINDER_EMPLOYEES: 'All employees',
  PENDING_TIMECARD_REMINDER_TITLE_GROUP: 'Send <<var1>> Group Reminder',
  PENDING_TIMECARD_REMINDER_SUBTITLE_GROUP: 'Pending timecards',
  PENDING_TIMECARD_REMINDER_SEND_LABEL_GROUP: 'Send reminder to: ',
  PENDING_TIMECARD_REMINDER_SEND_LABEL_LIST: 'Select <<var1>>:',
  // Reminder Modals - Pending Timecards
  PENDING_HIRES_REMINDER_TITLE: 'Send Pending Hires Reminder',
  PENDING_HIRES_REMINDER_SEND_LABEL_GROUP: 'Send reminder to: ',
  PENDING_HIRES_REMINDER_SEND_LABEL_LIST: 'Select <<var1>>:',
  // Reminder Modals - I9 Verification
  PENDING_HIRES_I9VERIFICATION_TITLE: 'Send I-9 Verification Reminder',
  PENDING_HIRES_I9VERIFICATION_CONTENT:
    'You are about to send a reminder to <<var1>> to bring in their I-9 documents for verification. ',
  // Reminder Modals - Crew Timecards Task/Reminder
  CREW_TIMECARDS_TASK_TITLE: 'Timecard reminder',
  CREW_TIMECARDS_TASK_CONTENT:
    'Remind my crew to submit outstanding timecards.',
  CREW_TIMECARDS_TASK_ALREADY_SENT:
    'Reminder sent by <<var1>> Next available reminder: <<var2>> ',
  CREW_TIMECARDS_TASK_TOOLTIP: `You can send a bulk email reminder to all crew
    members who have timecards in a draft,
    incomplete, ready for employee, or ready for
    department head status. `,
  CREW_TIMECARDS_TASK_TOOLTIP_2: `This action can only be taken once every 24 hours.`,
  CREW_TIMECARDS_MODAL_TITLE: 'Timecard reminder',
  CREW_TIMECARDS_MODAL_CONTENT: `You are about to send a bulk email reminder to all crew members with
    timecards in a draft, incomplete, ready for employee, or ready for
    department head status. This action can only be taken once per day.`,
  CREW_TIMECARDS_MODAL_INPUT_LABEL:
    'Choose the timecard week(s) you’d like to send reminders for: ',
  CREW_TIMECARDS_MODAL_CHECKBOX_CURRENT: 'Current Week Ending <<var1>>',
  CREW_TIMECARDS_MODAL_CHECKBOX_PREV: 'Previous Week Ending <<var1>>',
  STARTWORK_MODAL_TITLE: 'Send Startwork Reminder',
  STARTWORK_MODAL_CONTENT:
    'You are about to send a reminder to <<var1>> to complete and submit their offer.',
  // MyPayrollTasks
  MY_PAYROLL_TASKS_TITLE: 'My Tasks',
  MY_PAYROLL_TASKS_SUBTITLE: 'Your assigned tasks.',
  MY_PAYROLL_TASKS_EMPTY_TITLE: 'You are all caught up!',
  MY_PAYROLL_TASKS_EMPTY_TEXT: 'New items will appear here for you to tackle',
  MY_PAYROLL_TASKS_REJECTED_OFFERS_TITLE: '<<var1>> Rejected Offers',
  MY_PAYROLL_TASKS_REJECTED_OFFERS_TEXT:
    'A rejected offer is waiting for you to review',
  MY_PAYROLL_TASKS_REJECTED_OFFERS_LINK: 'Review',
  MY_PAYROLL_TASKS_PENDING_OFFERS_TITLE: '<<var1>> Pending Offers',
  MY_PAYROLL_TASKS_PENDING_OFFERS_TEXT:
    'There are offers awaiting your approval',
  MY_PAYROLL_TASKS_PENDING_OFFERS_LINK: 'Review',
  MY_PAYROLL_TASKS_I9S_TO_VERIFY_TITLE: '<<var1>> I-9s to Verify',
  MY_PAYROLL_TASKS_I9S_TO_VERIFY_TEXT: 'There are I-9s awaiting to be verified',
  MY_PAYROLL_TASKS_I9S_TO_VERIFY_LINK: 'Verify',
  MY_PAYROLL_TASKS_TIMECARDS_TITLE: '<<var1>> Timecards Ready for Me',
  MY_PAYROLL_TASKS_TIMECARDS_TEXT: 'There are timecards ready for your  review',
  MY_PAYROLL_TASKS_TIMECARDS_LINK: 'Review',
  MY_PAYROLL_TASKS_INVOICES_TITLE: '<<var1>> Edits to Review',
  MY_PAYROLL_TASKS_INVOICES_TEXT:
    'This may include revisions ready for your review',
  MY_PAYROLL_TASKS_INVOICES_LINK: 'Review',
  // Checks print widget
  CHECKS_PRINT_TITLE: 'Checks to print',
  CHECKS_PRINT_TEXT:
    'Track how many physical payroll checks are ready for printing.',
  CHECKS_PRINT_CHART_TITLE: 'Total Checks',
  CHECKS_PRINT_CHART_RELEASED: 'Invoices released',
  CHECKS_PRINT_CHART_UNRELEASED: 'Unreleased',
};
