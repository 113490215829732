import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from 'components/Dashboard/Typography';
import CsvButton from 'components/Dashboard/Button/CsvButton';

const CsvButtons = () => {
  const headers = [
    { key: 'employeeName', label: 'Employee Name' },
    { key: 'maskedSsn', label: 'Masked SSN' },
    { key: 'employeeEmailAddress', label: 'Email' },
    { key: 'jobDescription', label: 'Job' },
    { key: 'department', label: 'Department' },
    { key: 'account', label: 'Account' },
    { key: 'employeeAddress', label: 'Address' },
    { key: 'guaranteePay', label: 'Guaranteed Pay' },
    { key: 'guaranteeHr', label: 'Guaranteed Hour' },
    { key: 'guaranteeUnit', label: 'Guaranteed Unit' },
    { key: 'wkschdDescNote', label: 'Week Schedule Note' },
    { key: 'phoneNumber', label: 'Phone' },
    { key: 'startDate', label: 'Start Date' },
    { key: 'endDate', label: 'End Date' },
    { key: 'unionId', label: 'Union' },
    { key: 'kitRental', label: 'kitRental' },
    { key: 'kitAcct', label: 'kitAcct' },
    { key: 'carAllow', label: 'Car Allowance' },
    { key: 'carAcct', label: 'Car Account' },
    { key: 'cellAllow', label: 'Cell Allowance' },
    { key: 'cellAcct', label: 'Cell Account' },
    { key: 'perDiemMealAllow', label: 'Per Diem Meal Allowance' },
    { key: 'perDiemMealAcct', label: 'Per Diem Meal Account' },
  ];

  const data = [
    {
      employeeName: 'BARNHART, ADAM J.',
      maskedSsn: 'XXX-XX-4460',
      employeeEmailAddress: 'adamj..barnhart@test.com',
      jobDescription: 'POLICE',
      department: 'OPERATIONS',
      account: '36-31-00',
      employeeAddress: null,
      guaranteePay: null,
      guaranteeHr: null,
      guaranteeUnit: 'W',
      wkschdDescNote: null,
      phoneNumber: '(818) 555-1234',
      startDate: '2024-09-19',
      endDate: '2024-09-19',
      unionId: 'NONE',
      kitRental: null,
      kitAcct: null,
      carAllow: null,
      carAcct: null,
      cellAllow: null,
      cellAcct: null,
      perDiemMealAllow: null,
      perDiemMealAcct: null,
      __typename: 'CrewDetails',
    },
    {
      employeeName: 'BARNHART, ADAM J.',
      maskedSsn: 'XXX-XX-4460',
      employeeEmailAddress: 'adamj..barnhart@test.com',
      jobDescription: 'POLICE',
      department: 'OPERATIONS',
      account: '36-31-00',
      employeeAddress: null,
      guaranteePay: null,
      guaranteeHr: null,
      guaranteeUnit: 'W',
      wkschdDescNote: null,
      phoneNumber: '(818) 555-1234',
      startDate: '2024-09-19',
      endDate: '2024-09-19',
      unionId: 'NONE',
      kitRental: null,
      kitAcct: null,
      carAllow: '500',
      carAcct: null,
      cellAllow: null,
      cellAcct: null,
      perDiemMealAllow: null,
      perDiemMealAcct: null,
      __typename: 'CrewDetails',
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2 className="header">Download CSV Buttons</h2>
      </Grid>
      <Grid item xs={12}>
        <Typography body2 long>
          The CSV button is a styled button that is used to download a CSV file.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography body2 long>
          The following example demonstrates how to use the CSV button to
          download a CSV file.
        </Typography>
        <div style={{ overflow: 'scroll' }}>
          <table border={1} cellPadding={4}>
            <thead>
              <tr>
                {headers.map(header => (
                  <th key={header.key}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  {headers.map(header => (
                    <td key={`${i}|${header.key}`}>{item[header.key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <CsvButton data={data} headers={headers} fileName="test.csv">
              Download CSV file
            </CsvButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CsvButtons;
